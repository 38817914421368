import { FC } from "react";

import Link from "next/link";

import { FooterProps } from "./types";
import FooterStyledComponents from "./styled";

import ArrowRightUpSVG from "../../../../assets/svgs/arrow-right-up.svg";
import LogoSVG from "../../../../assets/svgs/FooterLogo.svg";

const {
  Container,
  LogoArea,
  LinkArea,
  CompanyLinks,
  SocialLinks,
  CopyrightArea,
} = FooterStyledComponents;

const Footer: FC<FooterProps> = () => {
  return (
    <Container>
      <LinkArea>
        <LogoArea>
          <Link href="/">
            <span>RDS Footer Logo</span>
            <LogoSVG />
          </Link>
        </LogoArea>
        <CompanyLinks>
          <Link href="https://www.r114.com/" target="_blank">
            <span>
              R114 <ArrowRightUpSVG />
            </span>
          </Link>
          <Link href="https://info.r114.com/r114/" target="_blank">
            <span>
              Company <ArrowRightUpSVG />
            </span>
          </Link>
        </CompanyLinks>
        <SocialLinks>
          <Link
            href="https://www.youtube.com/channel/UCXvGtSJUybn-j_grFsWJfuw"
            target="_blank"
          >
            <span>YouTube</span>
          </Link>
          <Link
            href="https://post.naver.com/my.nhn?memberNo=478443"
            target="_blank"
          >
            <span>NAVER Post</span>
          </Link>
          <Link href="https://blog.naver.com/r114korea" target="_blank">
            <span>NAVER Blog</span>
          </Link>
          <Link href="https://www.facebook.com/realestate114" target="_blank">
            <span>Facebook</span>
          </Link>
        </SocialLinks>
      </LinkArea>
      <CopyrightArea>
        <span>© R114 Corporation</span>
      </CopyrightArea>
    </Container>
  );
};

export default Footer;
