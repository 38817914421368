"use client";

import { FC } from "react";

import { MainPageProps } from "./types";
import MainPageStyledComponents from "./styled";

import { MainPageContext } from "./context";

import useSchene from "./useSchene";

import { Section01, Section02, Section03, Section04, Footer } from "./sections";

import BackTop from "./BackTop";

import Monitor from "./Monitor";

const { Container, SectionContainer } = MainPageStyledComponents;

const MainPage: FC<MainPageProps> = () => {
  const schene = useSchene([
    {
      totalFrame: 1,
    },
    {
      totalFrame: 2,
    },
    {
      totalFrame: 4,
    },
    {
      totalFrame: 1,
    },
    {
      totalFrame: 1,
    },
  ]);

  const renderBackTop = () => {
    {
      switch (schene.state?.currentScheneIndex) {
        case 2:
          return <BackTop color="black" />;
        case 3:
          return <BackTop color="white" />;
        case 4:
          return <BackTop color="white" />;
        default:
          return "";
      }
    }
  };

  return (
    <MainPageContext.Provider value={schene}>
      <Container
        style={{
          background: schene.state?.currentScheneIndex === 2 ? "#111" : "#fff",
        }}
      >
        <SectionContainer ref={schene.ref}>
          <Section01 />
          <Section02 />
          <Section03 />
          <Section04 />
          <Footer />
        </SectionContainer>
        {renderBackTop()}
        {process.env.NODE_ENV === "development" && <Monitor />}
      </Container>
    </MainPageContext.Provider>
  );
};

export default MainPage;
