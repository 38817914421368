import styled, { css } from "styled-components";
import { foundations } from "@r114dev/rds";

import { mobileBreakPoint } from "../../../constants/size";

const { getColor } = foundations;

const BackTopStyledComponents = {
  Container: styled.button`
    width: 56px;
    height: 56px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 44px;
    bottom: 44px;
    padding: 0;

    border-radius: 32px;
    border: none;

    cursor: pointer;

    ${({ color }) => {
      switch (color) {
        case "black":
          return css`
            background-color: transparent;
            svg {
              g {
                ${getColor("fill", "white")};
              }
            }
          `;
        case "white":
        default: {
          return css`
            ${getColor("background-color", "white")};
          `;
        }
      }
    }}

    span {
      width: 1px;
      height: 1px;

      overflow: hidden;
      position: absolute;
      margin: -1px;
      clip: rect(0 0 0 0);
    }

    svg {
      flex: 1;
    }

    @media (max-width: ${mobileBreakPoint}px) {
      width: 48px;
      height: 48px;

      right: 20px;
      bottom: 32px;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  `,
};

export default BackTopStyledComponents;
