import { FC, useContext } from "react";

import { MonitorProps } from "./types";
import MonitorStyledComponents from "./styled";
import { MainPageContext } from "../context";

const { Container } = MonitorStyledComponents;

const Monitor: FC<MonitorProps> = () => {
  const context = useContext(MainPageContext);
  return (
    <Container>
      <div>currentScheneIndex: {context.state?.currentScheneIndex}</div>
      <div>
        frame:{" "}
        {context.state?.schenes[context.state?.currentScheneIndex].currentFrame}{" "}
        / {context.state?.schenes[context.state?.currentScheneIndex].totalFrame}
      </div>
    </Container>
  );
};

export default Monitor;
