import { FC, useContext, useEffect, useMemo, useState } from "react";

import Link from "next/link";

import { Section04Props } from "./types";
import Section04StyledComponents from "./styled";

import { MainPageContext } from "../../context";

const { Container, Message, Cursor, Content, NavigationArea } =
  Section04StyledComponents;

const message =
  "Do you want to see what we made now?";

const Section04: FC<Section04Props> = () => {
  const { state } = useContext(MainPageContext);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (state?.currentScheneIndex && state.currentScheneIndex >= 3) {
      const timer = setInterval(() => {
        setIndex((prev) => {
          if (prev < message.length) {
            return prev + 1;
          } else {
            clearInterval(timer);
          }
          return prev;
        });
      }, 60);

      return () => {
        clearInterval(timer);
      };
    }
  }, [state?.currentScheneIndex]);

  const isAnimationEnd = useMemo(() => {
    return index === message.length;
  }, [index]);

  return (
    <Container>
      <Content>
        <Message>
          {message.slice(0, index)}
          <Cursor />
        </Message>
        <NavigationArea>
          {isAnimationEnd && (
            <ul>
              <li>
                <Link href="/foundations/overview" scroll={false}>
                  <span>Foundations</span>
                </Link>
              </li>
              <li>
                <Link href="/components/overview" scroll={false}>
                  <span>Components</span>
                </Link>
              </li>
              <li>
                <Link href="/data-lab/chart-style/color" scroll={false}>
                  <span>Data Lab</span>
                </Link>
              </li>
            </ul>
          )}
        </NavigationArea>
      </Content>
    </Container>
  );
};

export default Section04;
