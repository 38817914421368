import { FC, useContext } from "react";

import { BackTopProps } from "./types";
import BackTopStyledComponents from "./styled";

const { Container } = BackTopStyledComponents;

import { MainPageContext } from "../context";

import ToTop from "../../../assets/svgs/Arrows/to-top.svg";

const BackTop: FC<BackTopProps> = ({ color }) => {
  const { dispatch } = useContext(MainPageContext);

  return (
    <Container
      color={color}
      onClick={() => {
        dispatch({ type: "moveToTop", payload: undefined });
      }}
    >
      <span>처음 페이지로 이동하는 버튼</span>
      <ToTop />
    </Container>
  );
};

export default BackTop;
