"use client";

import MainPage from "../src/components/MainPage";

import "./home.css";

const Page = () => {
  return <MainPage />;
};

export default Page;
