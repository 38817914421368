import styled, { css } from "styled-components";
import { foundations } from "@r114dev/rds";

import { mobileBreakPoint, tabletBreakPoint } from "../../../../constants/size";

import { fadeInUp } from "../../../../utils/style";

const { getColor, typography } = foundations;

const Section02StyledComponents = {
  Container: styled.div<{ theme: { frame?: number } }>`
    width: 100%;
    height: 100%;

    position: relative;
    transition: background 1s;

    ${({ theme: { frame } }) => {
      return css`
        background: no-repeat 0% ${frame === 1 ? 0 : 50}% / 100%
          url(/images/main/03-bg.png);

        @media (max-width: ${mobileBreakPoint}px) {
          background: no-repeat 100% ${frame === 1 ? 0 : 100}% / 200%
            url(/images/main/03-bg-mobile.png);
        }
      `;
    }}
  `,

  RowContainer: styled.div``,

  Row: styled.div`
    width: 1280px;

    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 24px;

    padding: 240px;

    animation: ${fadeInUp} 1s;

    @media (max-width: ${tabletBreakPoint}px) {
      width: 100%;
      padding: 120px 40px;
    }
    @media (max-width: ${mobileBreakPoint}px) {
      padding: 120px 20px;
    }
  `,

  Text: styled.div<{ theme: { bold?: boolean } }>`
    white-space: pre;

    ${({ theme: { bold } }) => {
      if (bold) {
        return typography.heading02B;
      }
      return typography.heading03B;
    }}

    @media (max-width: ${mobileBreakPoint}px) {
      white-space: normal;
      ${typography.body01B}

      ${({ theme: { bold } }) => {
        if (bold) {
          return typography.body01B;
        }
        return typography.body02B;
      }}
    }
  `,

  BottomArea: styled.div`
    display: flex;
    gap: 120px;

    position: absolute;
    bottom: 48px;
    right: 0;
    z-index: 0;

    white-space: nowrap;
    font-family: LINE Seed Sans;
    font-size: 120px;
    font-style: normal;
    font-weight: 800;
    line-height: 1;
    ${getColor("color", "gray900")}

    @keyframes train {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(-100vw);
      }
    }

    animation: train 16s linear infinite;

    @media (max-width: ${tabletBreakPoint}px) {
    }
    @media (max-width: ${mobileBreakPoint}px) {
      font-size: 60px;
      gap: 36px;
    }
  `,
};

export default Section02StyledComponents;
