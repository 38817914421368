import styled from "styled-components";
import { foundations } from "@r114dev/rds";

import {
  mobileBreakPoint,
  tabletBreakPoint,
} from "../../../../constants/size";
import { fadeInUp } from "../../../../utils/style";

const { getColor } = foundations;

const Section04StyledComponents = {
  Container: styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: no-repeat bottom/contain url(/images/main/04-bg.png);

    @media (max-width: ${mobileBreakPoint}px) {
      background: no-repeat bottom/contain url(/images/main/04-bg-m.png);
    }
  `,

  Content: styled.div`
    width: 1280px;

    display: flex;
    flex-direction: column;
    gap: 144px;

    @media (max-width: ${tabletBreakPoint}px) {
      width: 100%;
      padding: 0 40px;
      gap: 100px;
    }

    @media (max-width: ${mobileBreakPoint}px) {
      padding: 0 20px;
      gap: 100px;
    }
  `,

  Message: styled.div`
    width: 100%;
    height: 192px;

    font-family: LINE Seed Sans;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    white-space: pre-wrap;
    ${getColor("color", "gray900")}

    @media (max-width: ${tabletBreakPoint}px) {
      font-size: 40px;
    }

    @media (max-width: ${mobileBreakPoint}px) {
      font-size: 24px;
    }
  `,

  Cursor: styled.div`
    width: 8px;
    height: 64px;

    display: inline-block;
    margin-left: 8px;

    ${getColor("background", "gray300")}

    @keyframes twinkle {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    animation: twinkle 0.6s infinite;

    @media (max-width: ${tabletBreakPoint}px) {
      height: 45px;
    }
    @media (max-width: ${mobileBreakPoint}px) {
      width: 4px;
      height: 27px;
    }
  `,

  NavigationArea: styled.div`
    height: 36px;

    ul {
      display: flex;
      gap: 60px;

      padding: 0;
      list-style-type: none;
      animation: ${fadeInUp} 1s;

      li {
        font-family: Pretendard;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;

        span {
          display: inline-flex;
          flex-direction: column;

          &:after {
            content: "";

            width: 100%;
            height: 3px;
            
            ${getColor("background", "gray900")}
          }

          &:hover {
            &:after {
              @keyframes expandBar {
                from {
                  width: 0%;
                }
                to {
                  width: 100%;
                }
              }
              animation: expandBar 1s;
            }
          }
        }
      }
    }

    @media (max-width: ${tabletBreakPoint}px) {
      ul {
        li {
          font-size: 20px;
        }
      }
    }

    @media (max-width: ${mobileBreakPoint}px) {
      height: 115px;
      ul {
        flex-direction: column;
        gap: 20px;
        li {
          font-size: 16px;
        }
      }
    }
  `,
};

export default Section04StyledComponents;
