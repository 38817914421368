import { FC, useContext } from "react";

import { Player } from "@lottiefiles/react-lottie-player";

import { Section03Props } from "./types";
import Section03StyledComponents from "./styled";

import divisionLottieJson from "../../lotties/Single-Divison.json";
import minusLottieJson from "../../lotties/Single-Minus.json";
import multipleLottieJson from "../../lotties/Single-Multiple.json";
import plusLottieJson from "../../lotties/Single-Plus.json";
import { MainPageContext } from "../../context";

const {
  Container,
  Content,
  Row,
  RowContainer,
  Title,
  LottieArea,
  NavigationArea,
  Hyphen,
} = Section03StyledComponents;

const Section03: FC<Section03Props> = () => {
  const { state } = useContext(MainPageContext);

  const frame = state?.schenes[state.currentScheneIndex].currentFrame;

  return (
    <Container>
      {state?.currentScheneIndex === 2 && (
        <Content>
          {frame === 1 && (
            <RowContainer>
              <Row>
                <LottieArea>
                  <Player src={plusLottieJson} autoplay loop />
                </LottieArea>
                <Title>Plus</Title>
              </Row>
              <Row>
                {
                  "디자이너는 디자인 시스템을 활용하여\n요소들을 조합하고 디자인의 효율을 향상시킵니다."
                }
              </Row>
            </RowContainer>
          )}
          {frame === 2 && (
            <RowContainer>
              <Row>
                <LottieArea>
                  <Player src={minusLottieJson} autoplay loop />
                </LottieArea>
                <Title>Minus</Title>
              </Row>
              <Row>
                {
                  "기획자는 불필요한 요소를 제거하거나 단순화하여 서비스의 복잡성을\n간소화하고 사용자 경험을 최적화합니다."
                }
              </Row>
            </RowContainer>
          )}
          {frame === 3 && (
            <RowContainer>
              <Row>
                <LottieArea>
                  <Player src={multipleLottieJson} autoplay loop />
                </LottieArea>
                <Title>Multiply</Title>
              </Row>
              <Row>
                {
                  "개발자는 재사용 가능한 컴포넌트를 개발하고 조합하여 다양한\n기능과 특성을 가진 서비스를 개발합니다."
                }
              </Row>
            </RowContainer>
          )}
          {frame === 4 && (
            <RowContainer>
              <Row>
                <LottieArea>
                  <Player src={divisionLottieJson} autoplay loop />
                </LottieArea>
                <Title>Divide</Title>
              </Row>
              <Row>
                {
                  "디자이너, 기획자 그리고 개발자는 정보와 지식을 공유하여 서로의\n역할과 전문성을 존중하며 효율적인 협업을 이룹니다."
                }
              </Row>
            </RowContainer>
          )}
          <NavigationArea>
            <b>0{frame}</b> <Hyphen /> 04
          </NavigationArea>
        </Content>
      )}
    </Container>
  );
};

export default Section03;
