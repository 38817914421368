import { FC } from "react";

import { Player } from "@lottiefiles/react-lottie-player";

import { Section01Props } from "./types";
import Section01StyledComponents from "./styled";

import { HeaderComponent } from "../../../Layout/Layout";

import lottieJSON from "../../lotties/_Img.json";

const {
  Container,
  ContentsContainer,
  RowContainer,
  Row,
  Text,
  RotateContainer,
  LottieArea,
} = Section01StyledComponents;

const Section01: FC<Section01Props> = () => {
  return (
    <Container>
      <HeaderComponent />
      <ContentsContainer>
        <RowContainer>
          <Row>
            <Text>RDS has a plan</Text>
          </Row>
          <Row>
            <Text>To enhances the work</Text>
          </Row>
          <Row>
            <RotateText
              words={["efficiency", "convenience", "expandability"]}
            />
          </Row>
        </RowContainer>
        <LottieArea>
          <Player src={lottieJSON} autoplay loop />
        </LottieArea>
      </ContentsContainer>
    </Container>
  );
};

export default Section01;

const RotateText: FC<{
  words: string[];
}> = ({ words }) => {
  return (
    <RotateContainer>
      {words.slice(0, 3).map((text, index) => {
        return <Text key={index}>{text}</Text>;
      })}
    </RotateContainer>
  );
};
