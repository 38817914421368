import styled, { css } from "styled-components";
import {
  mobileBreakPoint,
  tabletBreakPoint,
} from "../../../../constants/size";

const Section01StyledComponents = {
  Container: styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 240px 0;

    @media (max-width: ${tabletBreakPoint}px) {
      padding: 0;
    }
    @media (max-width: ${mobileBreakPoint}px) {
      position: relative;
    }
  `,

  ContentsContainer: styled.div`
    width: 1280px;
    height: 439px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: ${tabletBreakPoint}px) {
      width: 100%;
      height: 600px;

      margin: 0 40px;
    }

    @media (max-width: ${mobileBreakPoint}px) {
      height: 400px;
      margin: 0 20px;
    }
  `,

  RowContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Row: styled.div`
    display: flex;
    gap: 16px;

    @media (max-width: ${tabletBreakPoint}px) {
      gap: 0;
      flex-direction: column;
    }
  `,
  Text: styled.div`
    font-size: 72px;
    line-height: 150%;
    font-weight: 700;

    @media (max-width: ${tabletBreakPoint}px) {
      font-size: 56px;
    }
    @media (max-width: ${mobileBreakPoint}px) {
      font-size: 36px;
      &:nth-of-type(1) {
        letter-spacing: -0.5px;
      }
    }
  `,

  RotateContainer: styled.div`
    height: 108px;
    position: relative;
    transform-style: preserve-3d;

    @media (max-width: ${tabletBreakPoint}px) {
      height: 84px;
    }
    @media (max-width: ${mobileBreakPoint}px) {
      height: 54px;
    }

    ${() => {
      const length = 6;
      return css`
        @keyframes rotate {
          ${Array.from({ length: length + 1 }).map((_, index) => {
            const progress = (100 / length) * index;
            return `
                ${progress}% {
                  transform: rotateX(-${
                    120 * Math.ceil(progress / (100 / 3))
                  }deg)
                }
                `;
          })}
        }
      `;
    }}
    animation: rotate infinite 8s ease-in-out;

    > * {
      position: absolute;

      backface-visibility: hidden;

      &:nth-of-type(1) {
        transform: rotateX(0deg) translateZ(24px);
      }
      &:nth-of-type(2) {
        transform: rotateX(120deg) translateZ(24px);
      }
      &:nth-of-type(3) {
        transform: rotateX(240deg) translateZ(24px);
      }
    }

    @media (max-width: ${mobileBreakPoint}px) {
      > {
        &:nth-of-type(1) {
          transform: rotateX(0deg) translateZ(12px);
        }
        &:nth-of-type(2) {
          transform: rotateX(120deg) translateZ(12px);
        }
        &:nth-of-type(3) {
          transform: rotateX(240deg) translateZ(12px);
        }
      }
    }
  `,

  LottieArea: styled.div`
    width: 336px;
    height: 60px;

    align-self: flex-end;

    @media (max-width: ${mobileBreakPoint}px) {
      width: 224px;
      height: 40px;

      position: absolute;
      bottom: 80px;
    }
  `,
};

export default Section01StyledComponents;
