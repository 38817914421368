import { FC, useContext, useState } from "react";

import { Section02Props } from "./types";
import Section02StyledComponents from "./styled";
import { MainPageContext } from "../../context";

const { Container, RowContainer, Text, Row, BottomArea } =
  Section02StyledComponents;

const Section02: FC<Section02Props> = () => {
  const { state } = useContext(MainPageContext);

  const frame = state?.schenes[state.currentScheneIndex].currentFrame;

  return (
    <Container theme={{ frame }}>
      <RowContainer>
        {frame === 1 && (
          <Row>
            <Text theme={{ bold: true }}>
              {
                "We created a design system\nthat allows us to focus on a consistent user experience."
              }
            </Text>
            <Text>
              우리는 일관된 사용자 경험에 집중할 수 있는 디자인 시스템을
              만들었습니다.
            </Text>
          </Row>
        )}
        {frame === 2 && (
          <Row>
            <Text theme={{ bold: true }}>
              We have created common rules for designers, developers and PM.
            </Text>
            <Text>
              그다음 디자이너, 개발자, 기획자의 공통의 규칙을 정의했습니다.
            </Text>
          </Row>
        )}
      </RowContainer>
      {state?.currentScheneIndex === 1 && (
        <BottomArea>
          {Array.from({ length: 5 }).map((_, index) => {
            return <span key={index}>{`We're READY.`}</span>;
          })}
        </BottomArea>
      )}
    </Container>
  );
};

export default Section02;
