import styled from "styled-components";
import { foundations } from "@r114dev/rds";

import {
  mobileBreakPoint,
  tabletBreakPoint,
} from "../../../../constants/size";

const { getColor, typography } = foundations;

const FooterStyledComponents = {
  Container: styled.footer`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    ${getColor("background", "grayDimmedEffectBlack85D")}
    background-color: rgba(0, 0, 0, 0.85);
  `,

  LinkArea: styled.div`
    width: 1280px;
    height: 300px;

    display: flex;
    justify-content: end;
    gap: 140px;

    padding: 64px 0;

    span {
      ${typography.body02M}
    }

    @media (max-width: ${tabletBreakPoint}px) {
      width: 100%;
      gap: 100px;
      padding: 40px;
    }
    @media (max-width: ${mobileBreakPoint}px) {
      display: none;
    }
  `,

  LogoArea: styled.div`
    margin-right: auto;
    a {
      display: block;

      span {
        width: 1px;
        height: 1px;

        overflow: hidden;
        position: absolute;
        margin: -1px;
        clip: rect(0 0 0 0);
      }
    }
  `,

  CompanyLinks: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    span {
      display: flex;
      align-items: center;
      gap: 2px;
      /* svg {
        transition: 0.4s;
        transform: translate(0px, 0px);
      }
      &:hover {
        svg {
          transform: translate(2px, -2px);
        }
      } */

      ${getColor("color", "white")}
    }
  `,

  SocialLinks: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    ${typography.body02M}
    span {
      ${getColor("color", "gray600")}
    }
  `,

  CopyrightArea: styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    
    ${getColor("background", "gray900")}
    
    span {
      width: 1280px;
      padding: 54px 0 32px;

      ${typography.body03M}
      line-height: normal;
      ${getColor("color", "white")}
    }

    @media (max-width: ${tabletBreakPoint}px) {
      span {
        width: 100%;
        padding: 40px 40px 20px;
      }
    }

    @media (max-width: ${mobileBreakPoint}px) {
      span {
        padding: 40px 20px 20px;
      }
    }
  `,
};

export default FooterStyledComponents;
