import styled from "styled-components";
import { foundations } from "@r114dev/rds";

import {
  mobileBreakPoint,
  tabletBreakPoint,
} from "../../../../constants/size";

import { fadeInUp } from "../../../../utils/style";

const { getColor, typography } = foundations;

const Section03StyledComponents = {
  Container: styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    ${getColor("color", "white")}
  `,

  Content: styled.div`
    width: 1280px;
    height: 530px;

    display: flex;

    @media (max-width: ${tabletBreakPoint}px) {
      width: 100%;

      margin: 0 40px;
      padding: 60px 0;
    }
    @media (max-width: ${mobileBreakPoint}px) {
      margin: 0 20px;
    }
  `,

  RowContainer: styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 26px;

    animation: ${fadeInUp} 1s;
  `,

  Row: styled.div`
    width: 100%;

    display: flex;
    gap: 24px;
    align-items: center;

    white-space: pre;

    ${getColor("color", "white")}
    ${typography.heading04B}

    @media (max-width: ${mobileBreakPoint}px) {
      white-space: normal;
      ${typography.body01B}
    }
  `,

  Title: styled.div`
    ${getColor("color", "white")}
    ${typography.display03B}

    @media (max-width: ${tabletBreakPoint}px) {
      ${typography.heading02B}
    }
  `,

  LottieArea: styled.div`
    width: 40px;
    height: 40px;
  `,

  NavigationArea: styled.div`
    display: flex;
    gap: 24px;
    align-items: center;

    position: absolute;
    right: 291px;
    bottom: 80px;

    color: white;
    ${typography.heading03B}
    ${getColor("color", "gray600")};

    b {
      ${getColor("color", "white")};
    }
    @media (max-width: ${tabletBreakPoint}px) {
      left: 40px;
      bottom: 40px;
    }
    @media (max-width: ${mobileBreakPoint}px) {
      left: 20px;
      right: auto;
      bottom: 40px;
    }
  `,

  Hyphen: styled.div`
    width: 24px;
    height: 3px;

    ${getColor("background", "gray600")}
  `,
};

export default Section03StyledComponents;
