import { keyframes } from "styled-components";
import {
  mobileViewWidth,
  pcViewWidth,
  tabletViewWidth,
} from "../constants/size";

export const convertViewportWidth = (
  px: number,
  type: "pc" | "tablet" | "mobile",
) => {
  switch (type) {
    case "pc":
      return Math.round((px / pcViewWidth) * 100);
    case "tablet":
      return Math.round((px / tabletViewWidth) * 100);
    case "mobile":
      return Math.round((px / mobileViewWidth) * 100);
  }
};

export const getFadeInOutOpacity = (frameRate: number, breakFrame: number) => {
  if (frameRate < breakFrame) {
    return frameRate / breakFrame;
  } else if (frameRate >= 100) {
    return 0;
  } else if (frameRate >= breakFrame) {
    return 1 - (frameRate - breakFrame) / (100 - breakFrame);
  }
};

export const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(8%);
  } to {
    opacity: 1;
    transform: translateY(0);
  }
`;
