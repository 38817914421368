import styled from "styled-components";

const MonitorStyledComponents = {
  Container: styled.nav`
    position: fixed;
    right: 100px;
    bottom: 100px;

    * {
      color: red;
    }
  `,
};

export default MonitorStyledComponents;
