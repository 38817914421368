import styled from "styled-components";

const MainPageStyledComponents = {
  Container: styled.div`
    width: 100%;
    height: 100%;
    
    overflow: hidden;

    position: fixed;

    box-sizing: border-box;
    transition: 1s;

    * {
      font-family: "LINE Seed Sans";
    }
  `,

  SectionContainer: styled.div`
    width: 100%;
    height: 100%;

    transition: 0.6s ease-in-out;
  `,
};

export default MainPageStyledComponents;
